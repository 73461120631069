$primaryColor: #9e292b;
$primaryColorOverlay: rgba(158, 41, 43, 0.12);
$secondaryColor: #3a424b;
$accentColor: #e98404;
$grayDarkColor: #3a424b;

$logoGradient: linear-gradient(35deg, #9e292b 35%, #f6a800);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") no-repeat center;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg")
	no-repeat center;
$logoBackgroundMobile: url("/images/logo/logo-dbildungsMobile.svg") no-repeat
	center;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar {
	.sc-branding {
		padding: 0;
		.cloud-logo {
			width: 100%;
			margin: 0;
			padding: 30px;
		}
		.sc-title {
			display: none;
		}
	}

	@media (max-width: 991px) {
		.sc-branding {
			padding: 0;
			.cloud-logo {
				background: $logoBackgroundTablet !important;
				height: 32px;
			}
		}
	}
}

@media (max-width: 575px) {
	.cloud-logo {
		background: $logoBackgroundMobile !important;
	}
}

.primary-color {
	color: $primaryColor;
	display: none;
}
.secondary-color {
	color: $secondaryColor;
	display: none;
}
@import "./bootstrap/scss/bootstrap-flex";
@import "./bootstrap/scss/_animation"; // this import is needed to enable the toggle feature of the detail card
@import "static/styles/lib/colors";

.error-header-wrapper{
    width: 100%;
    text-align: center;
    .error-header-image{
        width: 20vw;
        margin-bottom: -5vw;
        @include media-breakpoint-down(xs) {
            width: 70vw;
        }

		#COLOR {
			fill: $primaryColor;
		}
    }
    .error-header-code{
        font-size: 10vw;
        min-height: .75em;
        margin: 0;
        @include media-breakpoint-down(xs) {
            font-size: 5rem;
        }
        font-weight: bold;
    }
    .error-header-text{
        font-size: 2rem;
        font-weight: bold;
    }
}

.error-details-wrapper{
    text-align: center;
    /* toggles text depending on toggle state */
    #error-toggle-details{
        &[aria-expanded="false"]{
            .show{
                display: initial
            }
            .hide{
                display: none;
            }
        }
        &[aria-expanded="true"]{
            .show{
                display: none
            }
            .hide{
                display: initial;
            }
        }
    }
    #error-details{
        text-align: left;
        max-width: 900px;
        margin: 0 auto;
        .error-description{
            padding: 1rem;
        }
        pre{
            margin: 0;
        }
    }
}
